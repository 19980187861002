import styled from "styled-components"

export const ShortcutsWrapper = styled.div`
  position: relative;
  width: 0;
  height: 0;
`

export const ShortcutsContainer = styled.div`
  position: fixed;
  top: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 10px;
  z-index: 1000;
  @media (max-width: 710px) {
    right: unset;
    left: 10px;
    bottom: 10px;
    justify-content: flex-end;
    top: unset;
  }
`
export const IconWrapper = styled.div`
  border: 1px solid #8ea9d7;
  border-radius: 5px;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #426fbd;
  margin: 5px;
  & > svg {
    transition: 0.3s;
    width: 36px;
    height: 36px;
    color: ${props => props.theme.colors.white};
  }
  &:hover {
    cursor: pointer;
    transform: rotateZ(10deg) translateX(-10px);
    & > svg {
      color: ${props => props.theme.colors.orange};
    }
  }
`
