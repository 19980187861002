import styled from "styled-components"
import IElement from "./types"

export const Element = styled.div<IElement>`
  position: absolute;
  width: 48px;
  height: 5px;
  background-color: white;
  border-radius: 5px;
  top: ${props => props.top}px;
  transition: 0.3s;
`
