import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

export const HeaderWrapper = styled.div`
  z-index: 800;
  top: 0px;
  position: fixed;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  justify-content: center;
`

export const DesktopMenuBackground = styled.div`
  z-index: 500;
  top: 0px;
  position: fixed;
  width: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
  height: 45px;
  background-color: ${props => props.theme.colors.darkBlue};

  @media (max-width: 1180px) {
    height: 40px;
  }
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1470px;
  @media (max-width: 1470px) {
    width: 100%;
  }
`

export const LogoWrapper = styled.div`
  width: 250px;
  height: 250px;
  z-index: 450;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 1080px) {
    justify-content: unset;
    padding-left: 5px;
  }
`

export const LogoImage = styled(StaticImage)`
  position: relative;
  z-index: 450;
`
export const DesktopNavigation = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1280px) {
    display: none;
  }
`

export const ServicesMenu = styled.div`
  transition: 0.3s;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 840px;
  width: 180px;
  height: 270px;
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.darkBlue};
  border-radius: 5px;
  opacity: 0;
`

export const ServicesMenuWrapper = styled.div`
  &:hover > div {
    display: flex;
    visibility: visible;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
  & > div > a {
    text-align: center;
    color: ${props => props.theme.colors.darkBlue};
    z-index: 600;
    margin: 10px;
  }
`
