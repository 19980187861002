import React from "react"
import {
  MobileMenuContainer,
  MenuCard,
  MobileNavLink,
  LinkWrapper,
} from "./style"
import IMobileMenu from "./types"

const navLinksData = [
  {
    id: 1,
    name: "Services",
    slug: "/services",
  },
  {
    id: 2,
    name: "Products",
    slug: "/products",
  },
  {
    id: 3,
    name: "Case Studies",
    slug: "/case-studies",
  },
  {
    id: 4,
    name: "Career",
    slug: "/career",
  },
  {
    id: 5,
    name: "Talents Forge",
    slug: "/talents-forge",
  },
  {
    id: 6,
    name: "About Us",
    slug: "/about-us",
  },
  {
    id: 7,
    name: "Blog",
    slug: "/blog",
  },
  {
    id: 8,
    name: "Meetups",
    slug: "/meetups",
  },
]

const MobileMenu = (props: IMobileMenu) => {
  const { isActive, isVisible } = props
  return (
    <MobileMenuContainer isActive={isActive} isVisible={isVisible}>
      <MenuCard isActive={isActive} isVisible={isVisible}>
        <nav>
          {navLinksData.map(s => (
            <LinkWrapper key={s.id}>
              <MobileNavLink to={s.slug}>{s.name}</MobileNavLink>
            </LinkWrapper>
          ))}
        </nav>
      </MenuCard>
    </MobileMenuContainer>
  )
}

export default MobileMenu
