import React, { useState, useEffect } from "react"
import { PropsWithChildren } from "react"
import Header from "../../Sections/Header"
import GlobalStyles from "../../../globalStyle"
import Footer from "../../Sections/Footer"
import SocialShortcuts from "../SocialShortcuts"

interface ILayoutProps {}

const Layout = (props: PropsWithChildren<ILayoutProps>) => {
  const [tawkLoaded, setTawkLoaded] = useState(false)
  useEffect(() => {
    const loadTawk = () => {
      window.removeEventListener("scroll", loadTawk)
      setTawkLoaded(true)
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0]
      s1.async = true
      s1.src = "https://embed.tawk.to/60db064165b7290ac6387e00/1f9bp0sdj"
      s1.charset = "UTF-8"
      s1.setAttribute("crossorigin", "*")
      s0.parentNode.insertBefore(s1, s0)
    }

    if (tawkLoaded === false) {
      window.addEventListener("scroll", loadTawk)
    }
  }, [])
  const { children } = props
  return (
    <>
      <GlobalStyles />
      <SocialShortcuts />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
