import React from "react"
import { Element } from "./style"
import IElement from "./types"

const ButtonElement = (props: IElement) => {
  const { top, skew } = props
  return <Element top={top} skew={skew} />
}

export default ButtonElement
