import styled, { css } from "styled-components"
import { IMenuButtonWrapper } from "./types"

export const MenuButtonWrapper = styled.div<IMenuButtonWrapper>`
  display: none;
  position: fixed;
  width: 48px;
  height: 41px;
  top: 20px;
  right: 20px;
  z-index: 1200;
  cursor: pointer;

  ${props =>
    props.menuActive &&
    css`
      & > div:nth-child(1) {
        transform: translateY(18px) rotateZ(45deg);
      }
      & > div:nth-child(2) {
        background-color: transparent;
      }
      & > div:nth-child(3) {
        transform: translateY(-18px) rotateZ(-45deg);
      }
    `}

  &:hover {
    & > div:nth-child(1) {
      transform: translateY(-5px);
    }
    & > div:nth-child(3) {
      transform: translateY(5px);
    }
    & > div {
      background-color: ${props => props.theme.colors.orange};
    }

    ${props =>
      props.menuActive &&
      css`
        & > div:nth-child(1) {
          transform: translateY(18px) rotateZ(45deg);
        }
        & > div:nth-child(2) {
          background-color: transparent;
        }
        & > div:nth-child(3) {
          transform: translateY(-18px) rotateZ(-45deg);
        }
      `}
  }

  @media (max-width: 1280px) {
    display: block;
  }
`
