import React from "react"
import { ShortcutsWrapper, ShortcutsContainer, IconWrapper } from "./style"
import { Icon } from "@iconify/react"
import facebookIcon from "@iconify-icons/jam/facebook"
import linkedinOption from "@iconify-icons/grommet-icons/linkedin-option"

const SocialShortcuts = () => {
  return (
    <ShortcutsWrapper>
      <ShortcutsContainer>
        <a
          href="https://www.facebook.com/OakfusionSoftwareHouse"
          target="blank"
          aria-label="Facebook"
        >
          <IconWrapper>
            <Icon icon={facebookIcon} />
          </IconWrapper>
        </a>
        <a
          href="https://www.linkedin.com/company/oakfusion/"
          target="blank"
          aria-label="LinkedIn"
        >
          <IconWrapper>
            <Icon icon={linkedinOption} />
          </IconWrapper>
        </a>
      </ShortcutsContainer>
    </ShortcutsWrapper>
  )
}

export default SocialShortcuts
