import styled, { createGlobalStyle, keyframes } from "styled-components"
import {
  IH2Props,
  IH3Props,
  IContainerProps,
  IPProps,
} from "./helpers/interfaces"
import { Link } from "gatsby"
import { InlineIcon } from "@iconify/react"

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap"');
  body {
    font-display: swap;
    position: relative;
    margin: 0px;
    padding: 0px;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
  }
  a {
    color: white;
  text-decoration: none;
  }
  section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  }
  address {
    font-style: normal;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: "Rubik", sans-serif;
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: "Rubik", sans-serif;
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: "Rubik", sans-serif;
}
:-moz-placeholder { /* Firefox 18- */
  font-family: "Rubik", sans-serif;
}
`

export const ReadMoreLink = styled.span`
  display: flex;
  color: ${props => props.theme.colors.darkBlue};
  font-size: ${props => props.theme.fontSizes.normal};
  font-weight: ${props => props.theme.fontWeight.bold};
  transition: 0.3s;
  &:hover {
    color: ${props => props.theme.colors.orange};
  }
  &:hover > div {
    transform: translateX(5px);
  }
`

export const ButtonIcon = styled(InlineIcon)``
export const IconWrapper = styled.div`
  transition: 0.3s;
  z-index: 450;
  margin-left: 5px;
`

export const DoubleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";
  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
  }
`
export const BlogDoubleGrid = styled(DoubleGrid)`
  grid-template-columns: auto 300px;
  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
  }
`
export const PictureBlogDoubleGrid = styled(DoubleGrid)`
  grid-template-columns: 200px auto;
  @media (max-width: 730px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
  }
`
export const ProductDoubleGrid = styled(DoubleGrid)`
  grid-template-columns: 30% 70%;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
  }
`

export const TripleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . .";
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ". .";
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
  }
`
export const QuadrupleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . . .";
  @media (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      ". ."
      ". . ";
  }
  @media (max-width: 530px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      ". "
      ". "
      ". "
      ". ";
  }
`

export const GrayBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: lightGray;
  margin: 0 0 20px 0;
`
export const TagsBorder = styled(GrayBorder)`
  background-color: ${props => props.theme.colors.orange};
`

export const M = styled.div`
  margin: 15px;
`

export const OrangeBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.orange};
  margin: 20px 0 5px 0;
`

export const Underline = styled.div`
  position: relative;
  width: 50px;
  height: 4px;
  background-color: ${props => props.theme.colors.orange};
  z-index: 450;
  margin-bottom: 25px;
`

export const H2Title = styled.h2<IH2Props>`
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "initial")};
  position: relative;
  text-align: ${props => (props.center ? "center" : "unset")};
  font-size: ${props => props.theme.fontSizes.huge};
  color: ${props => (props.color ? props.color : props.theme.colors.darkBlue)};
  font-weight: ${props => (props.thin ? "normal" : "bold")};
  @media (max-width: 640px) {
    font-size: ${props => props.theme.fontSizes.medium};
  }
`

export const H3Title = styled.h3<IH3Props>`
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: ${props => props.theme.fontSizes.average};
  color: ${props => (props.isDarkBlue ? props.theme.colors.darkBlue : "white")};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: ${props => (props.center ? "center" : "unset")};
`
export const OrangeSpan = styled.span`
  color: ${props => props.theme.colors.orange};
`
export const DarkOrangeSpan = styled.span`
  color: ${props => props.theme.colors.darkOrange};
`
export const Section = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`
export const Container = styled.div<IContainerProps>`
  display: ${props => (props.isFlex ? "flex" : "block")};
  justify-content: ${props =>
    props.justifyContent ? "space-between" : "center"};
  margin: 30px 0 30px 0;
  width: 1470px;
  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const FlexCenter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const ColRight = styled(FlexCenter)`
  align-items: flex-end;
`

export const ColLeft = styled(FlexCenter)`
  align-items: flex-start;
`

export const P = styled.p<IPProps>`
  position: relative;
  font-size: ${props =>
    props.size ? props.size : props.theme.fontSizes.small};
  color: ${props => (props.color ? props.color : props.theme.colors.darkBlue)};
  text-align: ${props => (props.center ? "center" : "unset")};
  line-height: ${props => (props.lineHeight ? props.lineHeight : "unset")};
`

export const NavLink = styled(Link)`
  z-index: 1100;
  padding-right: 12px;
  padding-left: 13px;
  position: relative;
  font-size: 1.125rem;
  font-weight: 400;
  transition: 0.3s;

  &:hover {
    color: ${props => props.theme.colors.orange};
  }
`
export const ServicesNavLink = styled(NavLink)`
  &:hover > {
    color: ${props => props.theme.colors.orange};
  }
`

const gradientAnimation = keyframes`
    0% {
        transform: translateX(0%);
    }
    50%{
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0%);
    }
`

export const AnimatedBackground = styled.div`
  z-index: 400;
  position: absolute;
  animation: 20s ease-in-out infinite ${gradientAnimation};
  width: 210%;
  height: 100%;
  background-size: 400vw 400vh;
  left: 0;
  background-repeat: repeat;
  background: rgb(31, 55, 96);
  background: linear-gradient(
    45deg,
    rgba(31, 55, 96, 1) 0%,
    rgba(88, 135, 217, 1) 47%,
    rgba(88, 135, 217, 1) 53%,
    rgba(31, 55, 96, 1) 100%
  );
`
export const NavGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 30px 0 0 0;
  & > div > div > div > a {
    color: ${props => props.theme.colors.darkBlue};
    font-weight: ${props => props.theme.fontWeight.bold};
    margin: 5px 10px 5px 10px;
    transition: 0.3s;
    &:hover {
      color: ${props => props.theme.colors.orange};
    }
  }
  @media (max-width: 1180px) {
    flex-direction: column;
    & > div:last-child {
      margin-top: 30px;
      margin-left: auto;
    }
  }
`

export const Q = styled.q`
  &:before,
  &:after {
    content: "";
  }
  font-style: italic;
`
export const Col = styled.div`
  display: flex;
  flex-direction: column;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const Output = styled.div`
  & > h1 {
    font-size: 45px;
  }
  & > h2 {
    font-size: 24px;
  }
  & > h3 {
    font-size: 20px;
  }
  & > p > a {
    color: ${props => props.theme.colors.orange};
  }
  & > p,
  li,
  h2,
  h3 {
    color: ${props => props.theme.colors.darkBlue};
  }
  & > h2,
  h3 {
    color: ${props => props.theme.colors.orange};
  }
  & > p {
    line-height: 25px;
    font-size: 18px;
  }

  @media (max-width: 450px) {
    & > h2 {
      font-size: ${props => props.theme.fontSizes.small};
    }
  }
`

export default GlobalStyles
