import React, { PropsWithChildren } from "react"
import { Link } from "gatsby"
import chatDots from "@iconify-icons/bi/chat-dots"
import { Wrapper, Shape, Animation, ButtonText, ButtonIcon } from "./style"
import IButton from "./types"

const Button = (props: PropsWithChildren<IButton>) => {
  const { children, slug, icon } = props
  return (
    <Wrapper>
      <Link to={slug}>
        <Shape>
          <Animation />
          <ButtonText>{children}</ButtonText>
          <ButtonIcon height={22} icon={icon ? icon : chatDots} />
        </Shape>
      </Link>
    </Wrapper>
  )
}

export default Button
