import React from "react"
import { Link } from "gatsby"
import {
  TripleGrid,
  Wrapper,
  BackgroundWrapper,
  FooterLink,
  BottomText,
} from "./style"

import {
  Section,
  H3Title,
  Container,
  P,
  OrangeSpan,
  Underline,
  GrayBorder,
} from "../../../globalStyle"

const Footer = () => {
  return (
    <Section>
      <BackgroundWrapper>
        <Container>
          <TripleGrid>
            <Wrapper>
              <H3Title>Quick links</H3Title>
              <Underline />
              <FooterLink to="/meetups">Meetups</FooterLink>
              <FooterLink to="/career">Career</FooterLink>
              <FooterLink to="/blog">Blog</FooterLink>
            </Wrapper>
            <Wrapper>
              <H3Title>Services</H3Title>
              <Underline />
              <FooterLink to="/digital-transformation">
                Digital Transformation
              </FooterLink>
              <FooterLink to="/team-leasing">Team Leasing</FooterLink>
              <FooterLink to="/web-app-development">
                Web App Development
              </FooterLink>
              <FooterLink to="/solutions-for-startups">
                Solutions For Startups
              </FooterLink>
            </Wrapper>

            {/*
              <Wrapper>
                <H3Title>Newsletter</H3Title>
              <Underline />
              <div>
                <NewsletterInput
                  placeholder="Your email here"
                  type="text"
                  name="email"
                />
                <ButtonArrow
                  slug=""
                  color="white"
                  backgroundColor="#f17313"
                  hoverColor="white"
                >
                  Sign up!
                </ButtonArrow>
              </div>
               </Wrapper>
              */}
          </TripleGrid>
        </Container>
      </BackgroundWrapper>
      <Container>
        <TripleGrid>
          <Wrapper>
            <address>
              <H3Title isDarkBlue={true}>Office Poland</H3Title>
              <Underline />
              <P>
                Żmigrodzka 244 <br /> 51-131 Wrocław, Poland
              </P>
              <P>
                +48 797 743 064 <br />
                office@oakfusion.pl
              </P>
            </address>
          </Wrapper>
          <Wrapper>
            <address>
              <H3Title isDarkBlue={true}>Austria</H3Title>
              <Underline />
              <P>
                c/o Impact Hub Vienna <br /> Lindengasse 56/18-19 <br />
                1070 Wien, Austria
              </P>
              <P>
                +43 69919064248 <br />
                vienna@oakfusion.com
              </P>
            </address>
          </Wrapper>
          <Wrapper>
            <address>
              <H3Title isDarkBlue={true}>Nordic</H3Title>
              <Underline />
              <P>
                Anckargripsgatan 3 <br /> 211 19-SE Malmö Sweden
              </P>
              <P>office@oakfusion.pl</P>
            </address>
          </Wrapper>
        </TripleGrid>
      </Container>
      <GrayBorder />
      <Container>
        <BottomText>
          <Link to="/privacy-policy">
            <OrangeSpan>Privacy Policy</OrangeSpan>
          </Link>{" "}
          Copyright ©{" "}
          <Link to="/">
            <OrangeSpan>Oakfusion</OrangeSpan>
          </Link>{" "}
          2021
        </BottomText>
      </Container>
    </Section>
  )
}

export default Footer
