import styled from "styled-components"
import { Icon } from "@iconify/react"

export const Wrapper = styled.div`
  display: inline-block;
`

export const Shape = styled.div`
  overflow: hidden;
  background-color: ${props => props.theme.colors.orange};
  padding: 10px 25px 10px 25px;
  border-radius: 5px;
  z-index: 460;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 10px 0 10px;
  &:hover {
    & > div {
      transform: scale(1.3);
      border-radius: 300px;
    }
  }
`
export const Animation = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  transform: scale(0);
  background-color: ${props => props.theme.colors.darkBlue};
  border-radius: 0;
`

export const ButtonText = styled.span`
  font-size: 1.125rem;
  padding-right: 10px;
  z-index: 465;
`

export const ButtonIcon = styled(Icon)`
  z-index: 465;
`
