import React, { useState, useEffect } from "react"
import { MenuButtonWrapper } from "./style"
import ButtonElement from "./ButtonElement"
import MobileMenu from "../MobileMenu"

const MenuButton = () => {
  const [menuActive, setMenuActive] = useState<boolean>(false)
  const [display, setDisplay] = useState<boolean>(false)
  const toggleMenu = () => {
    if (display === false) {
      setDisplay(true)
      setMenuActive(true)
    } else {
      setTimeout(() => setDisplay(false), 400)
      setMenuActive(false)
    }
  }
  useEffect(() => {
    setMenuActive(false)
    function handleResize() {
      setMenuActive(false)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <>
      <MenuButtonWrapper onClick={toggleMenu} menuActive={menuActive}>
        <ButtonElement top={0} />
        <ButtonElement top={18} />
        <ButtonElement top={36} />
      </MenuButtonWrapper>
      <MobileMenu isActive={menuActive} isVisible={display} />
    </>
  )
}

export default MenuButton
