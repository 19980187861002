import React from "react"
import { Helmet } from "react-helmet"
import IHead from "./types"

const Head = (props: IHead) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <title>{`Oakfusion - ${props.pageTitle}`}</title>
      <link rel="canonical" href="https://oakfusion.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <meta lang="en" />
      {props.externalScriptUrl && (
        <script src={props.externalScriptUrl} async defer></script>
      )}
    </Helmet>
  )
}

export default Head
