import styled from "styled-components"
import { Link } from "gatsby"

export const BackgroundWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.darkBlue};
`
export const TripleGrid = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . .";
  @media (max-width: 1050px) {
    grid-template-columns: 450px 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      ". ."
      ". .";
  }
  @media (max-width: 700px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "."
      "."
      ".";
  }
`

export const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`
export const NewsletterInput = styled.input`
  background-color: white;
  border: none;
  border-radius: 5px;
  width: 250px;
  color: black;
  padding: 15px;
  font-size: ${props => props.theme.fontSizes.normal};
  margin: 15px 15px 15px 0;
  @media (max-width: 700px) {
    width: 200px;
  }
`
export const FooterLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.normal};
  line-height: 48px;
  transition: 0.3s;
  &:hover {
    color: ${props => props.theme.colors.orange};
  }
`
export const BottomText = styled.p`
  font-size: ${props => props.theme.fontSizes.verySmall};
  text-align: end;
  margin-right: 10px;
`
