import { StaticImage } from "gatsby-plugin-image"
import {
  Container,
  DesktopMenuBackground,
  HeaderWrapper,
  LogoWrapper,
  ServicesMenuWrapper,
  DesktopNavigation,
  ServicesMenu,
} from "./style"
import { NavLink, Section } from "../../../globalStyle"
import React from "react"
import Button from "../../UI/Button"
import MenuButton from "../../UI/MenuButton"

const linksData = [
  {
    id: 1,
    name: "Products",
    slug: "/products",
  },
  {
    id: 2,
    name: "Case Studies",
    slug: "/case-studies",
  },
  {
    id: 3,
    name: "Career",
    slug: "/career",
  },
  {
    id: 4,
    name: "Talents Forge",
    slug: "/talents-forge",
  },
  {
    id: 5,
    name: "About Us",
    slug: "/about-us",
  },
  {
    id: 6,
    name: "Blog",
    slug: "/blog",
  },
  {
    id: 7,
    name: "Meetups",
    slug: "/meetups",
  },
]

const Header = () => {
  return (
    <header>
      <Section>
        <DesktopMenuBackground />
        <HeaderWrapper>
          <Container>
            <NavLink to="/">
              <LogoWrapper>
                <StaticImage
                  width={155}
                  height={27}
                  src="../../../data/images/oakfusion-logo-white.png"
                  alt="logo"
                  layout="fixed"
                  placeholder="tracedSVG"
                />
              </LogoWrapper>
            </NavLink>
            <nav>
              <DesktopNavigation>
                <ServicesMenuWrapper>
                  <NavLink to="/services">Services</NavLink>
                  <ServicesMenu>
                    <NavLink to="/web-app-development">
                      Web App Development
                    </NavLink>
                    <NavLink to="/solutions-for-startups">
                      Solutions For Startups
                    </NavLink>
                    <NavLink to="/digital-transformation">
                      Digital Transformation
                    </NavLink>
                    <NavLink to="/team-leasing">Team Leasing</NavLink>
                  </ServicesMenu>
                </ServicesMenuWrapper>
                {linksData.map(s => (
                  <NavLink key={s.id} to={s.slug}>
                    {s.name}
                  </NavLink>
                ))}

                <Button slug="/contact">Contact</Button>
              </DesktopNavigation>
              <MenuButton />
            </nav>
          </Container>
        </HeaderWrapper>
      </Section>
    </header>
  )
}

export default Header
